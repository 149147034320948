<template>
  <div class="main">
    <el-tabs v-model="listQuery.type" @tab-click="handleClick">
      <el-tab-pane label="初级" name="1"></el-tab-pane>
      <el-tab-pane label="中级" name="2"></el-tab-pane>
      <el-tab-pane label="注册会计师" name="3"></el-tab-pane>
    </el-tabs>
    <div class="top">
      <div>
        <el-button size="small" type="primary" @click="addCourse" icon="Plus"
          >新增一级课程</el-button
        >
      </div>
    </div>
    <div>
      <el-table
        :data="list"
        style="width: 100%"
        row-key="id"
        border
        default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        :height="contentStyleObj"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column prop="name" label="课程名称" />
        <el-table-column prop="videoCode" label="课程编码" />
        <el-table-column align="center" label="操作" width="300">
          <template #default="scope">
            <el-button
              type="success"
              @click="addCourse2(scope.row)"
              plain
              size="small"
              icon="Star"
              >新增课程</el-button
            >
            <el-button
              type="primary"
              @click="addCourse2(scope.row, 'edit')"
              plain
              size="small"
              icon="Edit"
              >编辑</el-button
            >
            <el-button
              type="danger"
              @click="delCourse(scope.row)"
              plain
              size="small"
              icon="Delete"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
      />
    </div> -->
    <el-dialog v-model="dialogVisible" title="操作" width="30%">
      <el-form :model="temp" label-width="100px">
        <el-form-item label="类型：" v-if="dialogStatus == 'add'">
          <el-radio-group v-model="temp.type" size="small">
            <el-radio label="1">初级</el-radio>
            <el-radio label="2">中级</el-radio>
            <el-radio label="3">注册会计师</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="课程名称：">
          <el-input v-model="temp.name" size="small" />
        </el-form-item>
        <el-form-item label="课程vid：">
          <el-input v-model="temp.videoCode" size="small" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false" size="small"
            >取消</el-button
          >
          <el-button type="primary" @click="creatData" size="small">
            确认
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { deleteStudy, saveStudy, getStudy } from "../../api/class";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      listQuery: {
        type: "1",
      },
      list: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      dialogVisible: false,
      temp: {},
      dialogStatus: "",
      total: 0,
    };
  },
  created() {
    this.getList();
    this.contentStyleObj = this.$getHeight(300);
  },
  methods: {
    getList() {
      getStudy(this.listQuery).then((res) => {
        if (res.data.msg == "success") {
          this.list = res.data.data.list;
          this.total = res.data.data.total;
        }
      });
    },
    handleClick(tab) {
      this.listQuery.type = tab.props.name;
      this.getList();
    },
    //新增一级
    addCourse() {
      this.dialogVisible = true;
      this.dialogStatus = "add";
      this.temp = {
        name: "",
        videoCode: "",
        parentId: 0,
        type: "1",
      };
    },
    addCourse2(row, type) {
      this.dialogStatus == "";
      if (type == "edit") {
        this.temp = row;
      } else {
        this.temp = {
          name: "",
          videoCode: "",
          parentId: row.id,
          type: row.type,
        };
      }
      this.dialogVisible = true;
    },
    creatData() {
      saveStudy(this.temp).then((res) => {
        if (res.data.msg == "success") {
          this.dialogVisible = false;
          this.$qzfMessage("操作成功");
          this.getList();
        }
      });
    },
    delCourse(row) {
      deleteStudy({ id: row.id }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("删除成功");
          this.getList();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 98%;
  margin: 0 auto;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}
</style>
